@import "../../../assets/themes-vars.module";
@import "../../../assets/sizes-vars.module";

.home-card {
  &>* {
    padding: 1.5rem;
  }

  display: flex;
  border-radius: $normalCardRadius;
  background-image: url("../../../assets/images/card-background-mutual-blue.png");
  background-position: top right;
  width: 100%;
  height: $desktopCardHeight;
  box-shadow: 0.03rem 0.03rem;
  transition: all 0s;
}

.home-card:hover {
  box-shadow: 0.05rem 0.05rem;
}

.home-card-principal-data {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 75%;
  height: 100%;
}

.home-card-principal-data-box {
  width: 100%;
  height: 75%;
}

.home-card-other-data {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  height: 25%;
}

.home-card-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  align-content: flex-start;
  width: 25%;
  height: 100%;
}

.home-card-title {
  color: $lightTextSecondary;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
}

.home-card-balance {
  color: $lightTextPrimary;
  width: 100%;
  white-space: nowrap;
  font-size: 1.8rem;
  margin-top: 0.5rem;
}