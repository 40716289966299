@import "../../../../assets/themes-vars.module";
@import "../../../../assets/sizes-vars.module";

:root {
  --rowHeight: 47px;
}

.last-movements-cuenta-especifica-container {
  width: 100%;
  border-radius: $normalCardRadius;
  margin-left: $carouselPaddingLeft;
  margin-right: $carouselPaddingRight;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    margin-left: $carouselPaddingLeft / 4;
    margin-right: $carouselPaddingRight / 4;
  }
}

.last-movements-cuenta-especifica-header {
  background-color: $primaryMain;
  height: var(--rowHeight);
}

.last-movements-cuenta-especifica-row {
  align-content: center;
  border: thin solid;
  border-bottom: 0px;
  border-color: $primaryMain !important;
  min-height: 0px;
  height: var(--rowHeight);
  @media (max-width: 1535px) {
    height: calc(var(--rowHeight) - 1px);
  }
}

.last-movements-cuenta-especifica-empty-row {
  height: var(--rowHeight);
  border: thin solid;
  border-color: $primaryMain !important;
}

.last-movements-cuenta-especifica-hidden-section {
  border: thin solid;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-color: $primaryMain;
  border-bottom: 0px;
}

.last-movements-cuenta-especifica-hidden-section-download {
  width: 100%;
  display: flex;
  justify-content: end;
  padding-bottom: 5px;
}

.last-movements-cuenta-especifica-footer {
  align-items: center;
  background-color: $primaryMain;
  border: thin solid;
  border-color: $primaryMain;
  & > * {
    padding: 5px;
    margin: 0px;
  }
  height: var(--rowHeight);
}