@import "../../assets/themes-vars.module";

.login {
  display: inline-flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: $paper;
  border-width: 1px;
}

.login__container {
  margin: 50px;
  width: min(90vw, 500px);
  align-items: center;
  background-color: $paper;
}

.login__logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 4vh;
  padding-top: 0;
  align-items: center;
  text-align: right;
}

.login__form {
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-color: $paper;
  padding: 16px;
  width: 100%;
  font-size: 16px;
  color: $primaryMain;
}

.login__textBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 16px;
  font-size: 18px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.login__buttons__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap:15px;
}

.login__btn {
  display: inline-flex;
  justify-content: center;
  font-size: 18px;
  margin-bottom: 10px;
  color: $primaryLight;
  background-color: $primaryMain;
  width: 100%;
}

.login__btn:hover {
  background-color: $primary800;
}

.login_Recovery__btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 18px;
  margin: 0px 6px 0px 6px;
  color: $primaryLight;
  background-color: $primaryMain;
  width: 45%;
}

.error {
  font-size: 12px;
  text-align: left;
  color: red;
}
