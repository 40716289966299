@import "../../assets/themes-vars.module";

.transferencias-menu-main-container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}

.transferencia-individual-text-field {
  width: 60%;
}

.agenda-contactos-title {
  color: $primaryText;
  margin-top: 27px;
  margin-bottom: 15px;
  text-align: center;
  width: 50vw;
}

.nuevo-contacto-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  gap: 20px;
}

.socio-search-error {
  width: 100%;
  color: $primaryMain;
  margin: 15px;
  font-weight: normal;
  text-align: center;
}

@media screen and (max-width: 650px) {
  .agenda-contactos-title {
    width: 100%;
  }
}

.contacto-especifico-container {
  display: flex;
  height: 105px;
  justify-content: space-between;
}

.contacto-especifico-data-container {
  width: 90%;
  padding: 15px;
  padding-left: 10px;
  padding-right: 25px;
}

.contacto-especifico-actions-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-right: 5px;
}
