@import "../../../../assets/sizes-vars.module";

.quick-access-button {
  align-content: center;
  width: 9rem;
  height: 9rem;
}

.main-quick-access-div {
  padding-left: $carouselPaddingLeft;
  padding-right: $carouselPaddingRight;
  width: 100%;
  display: flex;
  align-items: center;
  height: 12rem;
  justify-content: space-around;
}

@media screen and (min-width: 2000px) {
  .quick-access-button {
    max-width: 185px;
    width: 16%;
    height: auto;
    aspect-ratio: 1/1;
  }
}

@media screen and (max-width: 1700px) {
  .quick-access-button {
    width: 8rem;
    height: 8rem;
  }
}

@media screen and (max-width: 1536px) {
  .main-quick-access-div {
    height: auto;
  }

  .quick-access-button {
    width: 9rem;
    height: 9rem;
  }
}

@media screen and (max-width: 1050px) {
  .main-quick-access-div {
    height: auto;
  }

  .quick-access-button {
    width: 7.5rem;
    height: 7.5rem;
  }
}

@media screen and (max-width: 900px) {
  .main-quick-access-div {
    height: auto;
  }

  .quick-access-button {
    width: 7rem;
    height: 7rem;
  }
}

@media screen and (max-width: 750px) {
  .main-quick-access-div {
    flex-wrap: wrap;
    gap: 15px;
  }
}

@media screen and (max-width: 600px) {
  .main-quick-access-div {
    padding-left: $carouselPaddingLeft / 2;
    padding-right: $carouselPaddingRight / 2;
  }
}