@import '../../assets/themes-vars.module';

.user-card {
    display: 'flex';
    align-items: center;
    padding: 10px;
    border-radius: 1rem;
    background-color: $grey200,
}

.usuario-content {
    color:  $primaryMain;
    margin: 0.5rem;
    max-width: 87%;
    padding: 0;
    border-radius: 1rem;
}

.menu-item-usuario-selected,
.menu-item-usuario-selected:hover {
    background-color: $grey200;
    color: $primaryMain;
    padding: 5px;
}

.menu-item-usuario-non-selected {
    color: $primaryMain;
    border-bottom: 1px solid $grey200;
    padding: 5px;
}

.header-card-usuario-title {
    font-size: 14px;
    line-height: 2.4rem;
    font-weight: 600;
}

.body-card-usuario-text {
    font-size: 11px;
}