@import "../../../../assets/themes-vars.module";

.linea-prestamo-resumen-cantidad-vigentes {
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 30px;
  margin-left: 15px;
  color: $lightTextTertiary;
}

.linea-prestamo-resumen-vencimiento {
  margin-top: 0.9rem; 
  color: $lightTextPrimary;
  font-weight: bold;
}
