.tyc-dialog-actions {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: end;
  align-items: center;
}

.tyc-dialog-content {
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.15);
}

.tyc-dialog-loader {
  display: flex;
  width: 100%;
  height: 50vh;
  justify-content: center;
}
