@import "../../../assets/themes-vars.module";
@import "../../../assets/sizes-vars.module";

.success-action-drawer-main-container {
  width: $drawerWidth;
  display: flex;
  flex-direction: column;
  height: $heightWithoutNavBar;
  padding: 10px;
  align-items: center;
  justify-content: center;
  gap: 30px;
  text-align: center;
}

.success-action-drawer-title {
  color: $primaryMain;
}

.success-action-drawer-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}
