@import "../../../../assets/themes-vars.module";

.inversiones-resumen-cantidad-vigentes {
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 30px;
  margin-left: 15px;
  color: $lightTextTertiary;
}

.inversiones-resumen-vencimiento {
  margin-top: 0.9rem; 
  color: $lightTextPrimary;
  font-weight: bold;
}

.no-clickable-delete-icon * {
  cursor: default !important;
}