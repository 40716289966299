@import "../../assets/_themes-vars.module.scss";

.drawer-form-items-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 10px;
}

.drawer-form-data-item {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
}

.drawer-form-data-item-label {
  font-weight: normal;
  color: $grey900;
}

.drawer-form-data-item-value {
  font-weight: bold;
  color: $grey900;
  text-align: right;
}

.drawer-form-action-item {
  display: flex;
  justify-content: end;
  gap: 15px;
}
