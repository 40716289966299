.change-password-main {
  align-self: center;
  min-height: 90vh;
}

.change-password-section {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.password-error {
  text-align: justify;
}