@import "../../../assets/themes-vars.module";
@import "../../../assets/sizes-vars.module";

.prestamo-page-card {
  & > * {
    padding: 1.5rem;
  }
  display: flex;
  border-radius: $normalCardRadius;
  background-image: url("../../../assets/images/card-background-mutual-blue.png");
  background-position: top right;
  width: 100%;
  height: $desktopCardHeight;
  box-shadow: 0.03rem 0.03rem;
  transition: all 0s;
  position: relative;
}

.prestamo-page-card:hover {
  cursor: pointer;
}

.prestamo-page-card-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 80%;
  height: 100%;
  padding: 0px;
  padding-left: 1.5rem;
}

.prestamo-card-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  align-content: flex-start;
  width: 20%;
  height: 100%;
}

.prestamo-page-line-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.prestamo-page-line1-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
}

.prestamo-page-line3-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: column;
}

.prestamo-card-importe-total {
  color: $lightTextPrimary;
  font-size: 1.8rem;
  margin-bottom: 0.8rem;
}

.prestamo-card-vencimiento {
  width: 160px;
  margin-top: 0.9rem;
  color: $lightTextPrimary;
  font-weight: bold;
}

.prestamo-card-vencimiento:hover {
  cursor: pointer;
}

.prestamo-card-destino {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: $normalCardRadius;
  color: $lightTextPrimary;
  font-weight: bold;
  bottom: 0;
  right: 0;
  width: 20%;
  min-width: 90px;
  height: 40px;
  background-color: $logoBackbround;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  padding: 0;
}
