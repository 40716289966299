@import "../../assets/themes-vars.module";

.field-set-container {
    width: 100%;
    color: $primaryMain;
    padding-top: 1rem;
    padding-bottom: 1rem;

}


.legend-container {
    color: $primaryMain;
    font-weight: bold;
    font-size: 16px;
}

.accordion-container {}

.field-set-container .accordion-container {
    border: 1px solid $primaryMain;
    border-radius: 1rem;
}

.date-picker {}

.date-picker * {
    width: 100%;
    border-color: $primaryMain;
}

.tercer-paso-constitucion {
    justify-content: center;
    align-items: center;
    margin-top: .5rem;
}

.tercer-paso-constitucion * {
    justify-content: center;
    align-items: center;
}