@import "../../../assets/sizes-vars.module";
@import "../../../assets/themes-vars.module";

.inversion-drawer-table-container {
  width: 100%;
}

.inversion-drawer-table-header {
  background-color: $primaryMain;
}

.inversion-drawer-table-row {
  & > * {
    border-color: $primaryMain;
    // border-left: thin solid transparent;
    border-bottom: thin solid transparent;
  }

  border-left: thin solid $primaryMain;
  border-right: thin solid $primaryMain;
  border-bottom: thin solid $primaryMain;
}

.inversion-drawer-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 5px;
}

.inversion-drawer-header-balance {
  font-size: 50px;
  font-weight: bold;
  color: $grey900 ;
  margin-bottom: 5px;
}

.inversion-drawer-header-subtitle {
  font-weight: normal;
  color: $grey900 ;
}

.grid-table-inversion * {
  border-top-color: transparent;
}

.hidden-content-inversion {
  margin: 0;
}

.footer-table-inversion {
  background-color: $primaryMain;
}

.inversion-list-hidden {
  & > * {
    border-bottom: thin solid transparent;
  }

  border-left: thin solid $primaryMain;
  border-right: thin solid $primaryMain;
  border-bottom: thin solid $primaryMain;
  margin-right: -0.1px;
  margin-left: -0.3px;
}

