//Card
$desktopCardHeight: 12rem;
$normalCardRadius: 1rem;

//Carousel-Padding
$carouselPaddingLeft: 1.85rem;
$carouselPaddingRight: 1.85rem;

//Page section Width
$sectionWidthMdUp: calc(100% - 60px);
$sectionWidthMdDown: calc(100% - 6px);

$drawerWidth: min(500px, 100vw);

$heightWithoutNavBar: calc(100vh - 48px - 10px - 10px);

:export {
  //Card
  desktopCardHeight: $desktopCardHeight;
  normalCardRadius: $normalCardRadius;

  //Carousel-Padding
  carouselPaddingLeft: $carouselPaddingLeft;
  carouselPaddingRight: $carouselPaddingRight;

  //Page section Width
  sectionWidthMdUp: $sectionWidthMdUp;
  sectionWidthMdDown: $sectionWidthMdUp;

  drawerWidth: $drawerWidth;

  heightWithoutNavBar: $heightWithoutNavBar;
}
