@import "../../assets/themes-vars.module";
@import "../../assets/sizes-vars.module";

.cuenta-especifica-table-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.cuenta-especifica-movimientos-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .cuenta-especifica-movimientos-header {
    width: calc(
      100% - ($carouselPaddingLeft + $carouselPaddingRight) / 4
    ) !important;
  }
}

.cuenta-especifica-default-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.cuenta-especifica-filters-section-div {
  margin-bottom: 0.5rem;
  width: calc(100% - ($carouselPaddingLeft + $carouselPaddingRight) / 4);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
