@import "../../../../assets/themes-vars.module";
@import "../../../../assets/sizes-vars.module";


.prestamo-table-container {
  width: 100%;
  margin: 5px;
}

.prestamo-table-header {
  background-color: $primaryMain;
  height: var(--rowHeight);
}

.prestamo-table-row {
  align-content: center;
  border: thin solid;
  border-bottom: 0px;
  border-color: $primaryMain !important;
  min-height: 0px;
  height: var(--rowHeight);
}

.prestamo-table-empty-row {
  height: var(--rowHeight);
  border: thin solid;
  border-color: $primaryMain !important;
}

.prestamo-table-hidden-section {
  border: thin solid;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-right: 1px;
  border-color: $primaryMain;
  border-bottom: 0px;
}

.prestamo-table-footer {
  align-items: center;
  background-color: $primaryMain;
  border: thin solid;
  border-color: $primaryMain;
  height: var(--rowHeight);
  & > * {
    padding: 5px;
    margin: 0px;
  }
}