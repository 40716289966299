@import '../../../../assets/themes-vars.module';
@import '../../../../assets/sizes-vars.module';

.no-elements-card {
    display: flex;
    border-radius: $normalCardRadius;
    background-image: url("../../../../assets/images/card-background-grey.png");
    background-position: top right;
    width: 100%;
    height: $desktopCardHeight;
    flex-wrap:wrap;
    box-shadow: 0.03rem 0.03rem;
    transition: all 0s;
}
.no-elements-card:hover {
    box-shadow: 0.05rem 0.05rem;
  }
.no-elements-principal-data{
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    text-align: center;
    height: 70%;
}

.no-elements-card-actions{
    display: flex;
    width: 100%;
    height: 30%;
    justify-content: center;
    align-items: center;
    padding: 0;
}
