@import "../../assets/themes-vars.module";
@import "../../assets/sizes-vars.module";

.home-main-container {
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.home-vertical-section {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: center;
}

.home-card-div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-bottom: 0;
  transition: 0ms;
}

.home-carousel {
  padding-left: $carouselPaddingLeft;
  padding-right: $carouselPaddingRight;
}

@media screen and (max-width: 600px) {
  .home-carousel {
    padding-left: $carouselPaddingLeft / 4;
    padding-right: $carouselPaddingRight / 4;
  }
}

.home-last-movements-div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 563px;
}

.home-quick-access-div {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  align-content: start;
  justify-content: center;
  width: 100%;
}

@media screen and (min-width: 1536px) {
  .home-card-div {
    width: 100%;
  }
}

@media screen and (max-width: 1536px) and (min-width: 400px) {
  .home-vertical-section {
    width: 98%;
  }
}

@media screen and (max-width: 400px) {
  .home-vertical-section {
    width: 90%;
  }
}
