@import "../../../assets/themes-vars.module";

.inversion-page-card-content * {
    color: $lightTextSecondary;
    font-weight: normal;
}

.inversion-page-card-content:hover {
    cursor: pointer;
}

.text-bold {
    font-weight: bold;
    color: $lightTextPrimary;
}