@import "../../../../../assets/themes-vars.module";
@import "../../../../../assets/sizes-vars.module";

.last-movements-container {
  width: 100%;
  border-radius: $normalCardRadius;
  margin-left: $carouselPaddingLeft;
  margin-right: $carouselPaddingRight;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .last-movements-container {
    margin-left: $carouselPaddingLeft /2;
    margin-right: $carouselPaddingRight /2;
  }
}

.last-movements-header {
  background-color: $primaryMain;
}

.last-movements-row {
  & > * {
    border-color: $primaryMain;
  }
  align-content: center;
  border: thin solid;
  border-color: $primaryMain;
  height: 53px;
}

.last-movements-empty-row {
  height: 53px;
  border: thin solid;
  border-color: $primaryMain !important;
}

.last-movements-footer {
  align-items: center;
  background-color: $primaryMain;
  border: thin solid;
  border-color: $primaryMain;
  height: 54px;
  & > * {
    padding: 0px;
    margin: 0px;
  }
}

.footer {
  & > * {
    margin: 0px;
  }
  display: flex;
  align-content: center;
  justify-content: center;
}
