@import "../../assets/themes-vars.module";
@import "../../assets/sizes-vars.module";

.prestamos-error-legend {
  text-align: center;
  font-weight: normal;
  width: 100%;
}

.lineas-prestamos-filter {
  margin-bottom: 13px;
  width: 250px;
}

.linea-prestamos-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.prestamos-cards-container{
  display: flex;
  justify-content: left;
}

.pay-prestamo-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: right;
  gap: 5px;
  align-items: end;
  margin-right: 15px;
  margin-bottom: 15px;
}

@media screen and (max-width: 600px) {
  .lineas-prestamos-filter {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
  }

  .prestamos-header {
    margin-top: 0;
  }

  .prestamos-cards-container{
    display: flex;
    justify-content: center;
  }
}

:host .MuiChip-colorPrimary {
  background-color: $primaryDark;
  align-items: left;
  justify-content: left;
}
