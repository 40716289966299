@import "../../assets/themes-vars.module";

.error-404-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-wrap: wrap;
}

.error-404-main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 70vw;
}

.error-404-image {
  height: 300px;
  width: 300px;
}

.error-404-404-text {
  margin-top: 30px;
  color: $primaryMain;
  width: 100%;
  text-align: center;
  font-size: 100px;
}

.error-404-page-not-found {
  margin-top: 10px;
  margin-bottom: 30px;
  color: $primaryText;
  width: 100%;
  text-align: center;
  font-size: 50px;
}

.error-404-text {
  color: $primaryText;
  text-align: center;
  width: 100%;
  font-size: 20px;
  margin-bottom: 15px;
}

.error-404-back-to-home {
  margin-top: 15px;
  width: 100%;
  position: absolute;
  bottom: 30px;
  text-align: center;
  font-size: 25px;
}

@media screen and (max-width: 600px) {
  .error-404-image {
    width: 250px;
    height: 250px;
  }

  .error-404-404-text {
    margin-top: -10px;
    margin-bottom: 0px;
  }

  .error-404-page-not-found {
    font-size: 35px;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .error-404-text {
    font-size: 15px;
    margin-bottom: 10px;
  }
}

@media screen and (max-height: 700px) {
    .error-404-back-to-home{
        position: relative;
        margin-top: 40px;
    }
  }
