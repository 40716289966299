@import "../../assets/themes-vars.module";

.nav-bar-client-name-text {
  color: $lightTextSecondary;
}

.nav-bar-system-date-text {
  color: $lightTextSecondary;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1280px) {
  .nav-bar-client-name-text {
    display: none;
  }
}