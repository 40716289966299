@import "../../assets/themes-vars.module";
@import "../../assets/sizes-vars.module";

.generic-main-div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  width: 100%;
  overflow-x: hidden;
  @media (max-width: 600px) {
    padding: 3px;
  }
}

.generic-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  min-width: 21.5rem;
}

.generic-card-div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 33%;
  padding-left: $carouselPaddingLeft / 2;
  padding-right: $carouselPaddingRight / 2;
  margin-bottom: 21px;
  transition: 0ms;
}

.generic-page-section {
  width: $sectionWidthMdUp;
}

@media screen and (max-width: 900px) {
  .generic-page-section {
    width: $sectionWidthMdDown;
  }
}
